import React, { useEffect, useContext } from 'react';
import { AppStateContext } from "./contexts/AppStateContext";
import { useParams } from "react-router-dom";

function ControlBar(props) {
    const contextValue = useContext(AppStateContext);
    const appState = contextValue.appState;
    const boardState = contextValue.boardState;


    var badCharsDisplay = null;
    let { boardURI } = useParams();

    if (appState.badChars.length) {
        badCharsDisplay = (
            <p>Too many instances of: {appState.badChars.join(', ')}</p>
        );
    }

    // Trigger a board build on first page load...
    useEffect(() => {
        if (boardState.boardMatrix === null) {
            if (boardURI) {
                appState.setBoardFromString(boardURI);
            } else {
                appState.randomBoard(boardState);
            }
        }
    });

    return (
        <div className="button-bar">
            {badCharsDisplay}
            <button onClick={() => props.setShowHelp(prevState => !prevState)}>
                <i className="fas fa-question"></i>
                &nbsp;
                Help
            </button>
            &nbsp;
            <button onClick={() => appState.randomBoard(boardState)}>
                <i className="fas fa-random"></i>
                &nbsp;
                New Board
            </button>
        </div>
    );
}

export default ControlBar;
