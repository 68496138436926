
var _ = require('lodash');
var letters = require('./letters.js');

exports.wordFromXY = function(word, matrix) {
    // word is array of chars

    var out = '';

    if (!matrix) {
        console.log("No matrix given to wordFromXY");
        return out;
    }

    word.forEach(c => {
        out += matrix[ c[0] ][ c[1] ];
    });
    return out;
}


// Example board
// ----------
// k  p  h  l
// o  f  e  h
// k  d  o  y
// e  a  j  k
// ----------
exports.build = function() {
    var set = letters.randomSet();
    // console.log("Pre random set: ", set);

    // random and unique key, so we won't select the same dice twice
    var ranSet = _.sampleSize(set, 16);
    var t = [
        [ranSet.shift(),ranSet.shift(),ranSet.shift(),ranSet.shift()],
        [ranSet.shift(),ranSet.shift(),ranSet.shift(),ranSet.shift()],
        [ranSet.shift(),ranSet.shift(),ranSet.shift(),ranSet.shift()],
        [ranSet.shift(),ranSet.shift(),ranSet.shift(),ranSet.shift()],
    ];

    // console.log("Post random set: ", t);
    return t;
}

exports.display = function(matrix, optWord = []) {
    console.log("\n\n----------", optWord ? " Coords: " : "", optWord);
    console.log(matrix[0].map(x => x ? x : ' ').join("\t"));
    console.log(matrix[1].map(x => x ? x : ' ').join("\t"));
    console.log(matrix[2].map(x => x ? x : ' ').join("\t"));
    console.log(matrix[3].map(x => x ? x : ' ').join("\t"));
    console.log("----------", optWord ? " Output: " : "", optWord ? exports.wordFromXY(optWord, matrix) : '', "\n");
}

exports.boardToString = function(board) {
    var out = '';
    for (var x = 0; x < 4; x++) {
        for (var y = 0; y < 4; y++) {
            out += board[x][y];
        }
    }

    return out;
}
