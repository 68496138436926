const _ = require('lodash');

exports.MAX_DICE = 16;

exports.randomSet = function() {
    let out = [];
    // For each of the sixteen die, roll it
    for (var i = 1; i <= exports.MAX_DICE; i++) {
        out.push(diceRoll(i));
    }
    return out;
}

exports.charArray = function() {
    var charA = 'a';
    var charZ = 'z';
    var out = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
        var cc = String.fromCharCode(i);
        cc = (cc === 'q' ? 'qu' : cc);
        out.push(cc);
    }
    return out;
}

exports.validDisti = function() {
    var validLetterCounts = [];
    for (var i = 1; i <= 16; i++) {
        var cc = diceArray(i);

        // important, we are counting unique dice, so multiples of a character
        // on same die will break our results
        cc = _.uniq(cc);

        cc.forEach(function(c) {
            if (!validLetterCounts[c]) {
                validLetterCounts[c] = 1;
            } else {
                validLetterCounts[c]++;
            }
        });
    }

    console.log(validLetterCounts);
    return validLetterCounts;
}


function ranChar(allChars) {
    return allChars[Math.floor(Math.random() * allChars.length)];
}

function diceArray(d) {
    if (!d) {
        throw new Error("Invalid dice request");
    }

    switch(d) {
        case 1:
            return [
                'd',
                'e',
                'i',
                'l',
                'r',
                'x',
            ];
        case 2:
            return [
                'a',
                'b',
                'b',
                'j',
                'o',
                'o',
            ];

        case 3:
            return [
                'h',
                'i',
                'm',
                'n',
                'qu',
                'u',
            ];

        case 4:
            return [
                'e',
                'l',
                'r',
                't',
                't',
                'y',
            ];

        case 5:
            return [
                'c',
                'i',
                'm',
                'o',
                't',
                'u',
            ];

        case 6:
            return [
                'h',
                'l',
                'n',
                'n',
                'r',
                'z',
            ];

        case 7:
            return [
                'e',
                'e',
                'g',
                'h',
                'n',
                'w',
            ];

        case 8:
            return [
                'a',
                'o',
                'o',
                't',
                't',
                'w',
            ];

        case 9:
            return [
                'e',
                'e',
                'i',
                'n',
                's',
                'u',
            ];

        case 10:
            return [
                'a',
                'f',
                'f',
                'k',
                'p',
                's',
            ];

        case 11:
            return [
                'a',
                'c',
                'h',
                'p',
                'qu',
                's',
            ];

        case 12:
            return [
                'd',
                'i',
                's',
                't',
                't',
                'y',
            ];

        case 13:
            return [
                'e',
                'h',
                'r',
                't',
                'v',
                'w',
            ];

        case 14:
            return [
                'd',
                'e',
                'l',
                'r',
                'v',
                'y',
            ];

        case 15:
            return [
                'e',
                'i',
                'j',
                'o',
                's',
                't',
            ];

        case 16:
            return [
                'a',
                'a',
                'e',
                'g',
                'n',
                'w',
            ];
        default:
            throw new Error("Unknown dice " + d + " requested!");
    }
}

function diceRoll(d) {
    if (!d) {
        throw new Error("Invalid dice roll request");
    }

    return ranChar(diceArray(d));
}

