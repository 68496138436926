import React, { useState, useContext } from 'react';
import { AppStateContext } from "./contexts/AppStateContext";
import './DebugBar.css';

function DebugBar(props) {
    const contextValue = useContext(AppStateContext);
    const appState = contextValue.appState;
    const boardState = contextValue.boardState;

    const updateMinSize = function(e, boardState, i) {
        e.preventDefault();
        appState.setMinWordSize(boardState, boardState.minWordSize + i);
    }
    const updateMaxSize = function(e, boardState, i) {
        e.preventDefault();
        appState.setMaxWordSize(boardState, boardState.maxWordSize + i);
    }

    const [ debugState, setDebugState ] = useState(false);

    const toggleDebugBar = function(e) {
        e.preventDefault();
        setDebugState(prevState => prevState ? false : true);
    }

    var debugClasses = 'debug-bar ';
    debugClasses += debugState ? 'debug-bar--show' : '';

    var maxScore = 0;
    appState.boardWords.forEach(function(w) {
        var l = w.length;
        var s = 0;

        //
        // length   Points
        // 3, 4     1
        // 5        2
        // 6        3
        // 7        5
        // 8+       11
        //
        if (l === 3 || l === 4) {
            s = 1;
        } else if (l === 5) {
            s = 2;
        } else if (l === 6) {
            s = 3;
        } else if (l === 7) {
            s = 5;
        } else if (l > 7) {
            s = 11;
        }
        maxScore += s;
    });


    return (
        <>
        <button className="debug-toggle" onClick={toggleDebugBar}>⚙</button>
        <div className={debugClasses}>
            <span>
                <strong>Board Status</strong>
            </span>
            <span>
                Min word:
                <button onClick={(e) => updateMinSize(e, boardState, -1)}>-</button>
                <strong>{boardState.minWordSize}</strong>
                <button onClick={(e) => updateMinSize(e, boardState, 1)}>+</button>
            </span>
            <span>
                Max word:
                <button onClick={(e) => updateMaxSize(e, boardState, -1)}>-</button>
                <strong>{boardState.maxWordSize}</strong>
                <button onClick={(e) => updateMaxSize(e, boardState, 1)}>+</button>
            </span>
            <span>
                WIP:
                <strong>{appState.wordCalcInProgress ? 'Yes' : 'No'}</strong>
            </span>
            <span>
                Max Score:
                <strong>{maxScore}</strong>
            </span>
        </div>
        </>
    );
}

export default DebugBar;
