import './WordList.css';
import React, { useState, useContext } from 'react';
import { AppStateContext } from "./contexts/AppStateContext";

function WordList(props) {
    const contextValue = useContext(AppStateContext);
    const appState = contextValue.appState;

    const baseDictionaryURL = 'https://api.dictionaryapi.dev/api/v2/entries/en/';
    const defineWord = function(w, pos) {
        if (!w) {
            setWordDefinition('');
        }
        appState.setCurrentWord(w, pos);
    }

    // WordList ordering...
    const [sortKey, setSortKey] = useState('az');
    const [sortDirection, setSortDirection] = useState('asc');

    const [fourPlus, setFourPlus] = useState(false);

    const [wordDefinition, setWordDefinition] = useState('');

     React.useEffect(() => {
        const axios = require('axios').default;
        var word = appState.currentWord;

        if (word == null || word === '') {
            return;
        }

        axios.get(baseDictionaryURL + word)
            .then(function (response) {
                // TODO: Could be multiple definitions...
                // TODO: Alert is a poor way to display the results...
                var def = null;
                if (response.data && response.data[0]) {
                    var wd = response.data[0];
                    if (wd.meanings && wd.meanings[0].definitions) {
                        def = wd.meanings[0].definitions[0];
                    }
                }

                if (def === null) {
                    console.log("Couldn't find a definition in response data...");
                    setWordDefinition('');
                } else {
                    setWordDefinition(def.definition);
                }
            })
            .catch(function (error) {
                // handle error
                console.log("Couldn't find a definition, error...");
                console.log(error);
                setWordDefinition('');
            })
            .then(function () {
                // always executed
            });
     }, [appState.currentWord]);

    var words = appState.boardWords;
    var wordInputs = appState.boardWordsInputs;

    if (sortKey === 'az') {
        words.sort();
    }
    if (sortKey === 'length') {
        words.sort(function(a,b) {
            return a.length - b.length;
        });
    }
    if (sortDirection === 'asc') {
        // no op, asc by default
    } else {
        words.reverse();
    }

    if (fourPlus) {
        // Fiter to those length 4+
        words = words.filter(word => word.length > 4);
    }

    const sortAZ = function(e) {
        e.preventDefault();
        setSortKey('az');
        setSortDirection(prevState => prevState === 'asc' ? 'desc' : 'asc');
    }
    const sortScore = function(e) {
        e.preventDefault();
        setSortKey('length');
        setSortDirection(prevState => prevState === 'asc' ? 'desc' : 'asc');
    }

    const showFourPlus = function(e) {
        e.preventDefault();
        setFourPlus(prevState => !prevState);
    }

    var out = [];
    words.forEach(function(w) {
        out.push(
            <li className={appState.currentWord === w ? "current-word" : ""} key={w} onClick={() => defineWord(w, wordInputs[w])}>{w}</li>
        );
    });

    var sortIcon = sortDirection === 'asc' ? <span>&uarr;</span> : <span>&darr;</span>;

    var wordDef = null;
    if (wordDefinition) {
        wordDef = (<>
            <strong>{appState.currentWord}:</strong> {wordDefinition}
            &nbsp;
            <button onClick={() => defineWord('', [])}>Close</button>
        </>);
    }

    return (
        <div>
            <div className="WordListControl">
                <strong className="sort-title">Word Count</strong>
                <span>{out && out.length ? out.length : '-'}</span>
                <strong className="sort-title">Sort</strong>
                <button onClick={sortAZ}>{sortKey === 'az' ? sortIcon : null} A-Z</button>
                <button onClick={sortScore}>{sortKey === 'length' ? sortIcon : null} Score</button>
                <strong className="sort-title">Filter</strong>
                <button onClick={showFourPlus}>{fourPlus ? 'Show All' : 'Show 5+'}</button>
            </div>
            <div className="WordList">
                {out && out.length
                    ? <ul>{out}</ul>
                    : (
                        <h4 className="words-loading">
                        <i className="fas fa-cog fa-spin"></i>
                        &nbsp;
                        Looking for some words...
                        </h4>
                    )
                }
            </div>
            {wordDef ? <p className="WordDefinition">{wordDef}</p> : null}
        </div>
    );
}

export default WordList;
