import '../../node_modules/csshake/dist/csshake.min.css';
import './WebBoard.css';
import React, { useContext } from 'react';
import { AppStateContext } from "./contexts/AppStateContext";
import Xarrow from "react-xarrows";


var letters = require('../Boggle/letters.js');

function WBLetter(props) {
    const contextValue = useContext(AppStateContext);
    const boardState = contextValue.boardState;

    const handleChange = function(e) {
        // e.target.value
        // e.target.name (e.g. '3:3')
        var [x,y] = e.target.name.split(':');
        props.setLetter(boardState, x, y, e.target.value);
    }

    return (
        <select className={"letter-input letter-input-" + props.cell + " " + (props.highlight ? "highlight" : "")} type="text" id={props.x + ":" + props.y} name={props.x + ":" + props.y} onChange={handleChange} value={props.cell}>
        {letters.charArray().map( c => <option key={c}>{c}</option> )}
        </select>
    );
}

function isHighlightedChar(set, x, y) {
    var hl = false;
    if (set) {
        set.forEach(function(pos) {
            if (!hl && pos[0] === x && pos[1] === y) {
                hl = true;
            }
        });
    }

    return hl;
}

function WebBoard(props) {
    const contextValue = useContext(AppStateContext);
    const appState = contextValue.appState;
    const boardState = contextValue.boardState;

    const boardInProgress = appState.wordCalcInProgress;
    const boardWords = appState.boardWords;
    const boldChars = appState.highlightPositions;

    var allRows = [];
    var xarrs = [];

    if (boldChars) {
        var start;
        var end = boldChars[0];
        var bright = false;
        for (var i = 1; i < boldChars.length; i++) {
            start = end
            end = boldChars[i];
            bright = !bright;
            xarrs.push(
                <Xarrow
                    start={start[0] + ":" + start[1]}
                    end={end[0] + ":" + end[1]}
                    startAnchor={"middle"}
                    endAnchor={"middle"}
                    strokeWidth={3}
                    headSize={5}
                    color={bright ? "rgba(235, 140, 131, 0.6)" : "rgba(202, 131, 235, 0.6)"}
                />
            );
        }
    }

    if (boardState && boardState.boardMatrix) {
        boardState.boardMatrix.forEach(function(row, i) {
            var cells = [];
            row.forEach(function(cell, k) {
                var hl = isHighlightedChar(boldChars, i, k);
                cells.push(
                    <td key={k}>
                        <WBLetter  highlight={hl} cell={cell} x={i} y={k} setLetter={appState.setLetter} />
                    </td>
                );
            });

            allRows.push(
                <tr key={i}>{cells}</tr>
            );
        });
    }

    // Check boardWords as well to avoid shaking on first page load, which can
    // be a little weird if the page takes more than a second or two to build
    // the dictionary.
    const shouldShake = boardInProgress && boardWords.length;

    return (
        <div className="WebBoard">
            <table className={"boggle-board-table " + (shouldShake ? "shake shake-constant" : "")}>
            <tbody>
            {allRows}
            </tbody>
            </table>

            {xarrs}

            {props.children}
        </div>
    );
}

export default WebBoard;
