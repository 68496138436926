import React, { useState, createContext } from "react";

export const AppStateContext = createContext();

const _ = require('lodash');
const letters = require('../../Boggle/letters.js');
const _generateWordsWorker = new window.Worker("wordsWorker.js");

var board = require('../../Boggle/board.js');

let MIN_WORD_SIZE = 3;
let MAX_WORD_SIZE = 16;

function buildBoardFromURI(boardURI) {

    // Handle the case where the URI sets the board
    var matrix = null;
    if (boardURI) {
        // Part 1 Mini hack, drop qu's to q
        boardURI = boardURI.replace('qu', 'q');
        var temp = boardURI.split('');

        if (temp.length === 16) {
            temp.forEach(function(v, ind, arr) {
                // Part 2, expand out again
                if (v === 'q') {
                    arr[ind] = 'qu';
                }
            });
            matrix = [
                [temp[0], temp[1], temp[2], temp[3]],
                [temp[4], temp[5], temp[6], temp[7]],
                [temp[8], temp[9], temp[10], temp[11]],
                [temp[12], temp[13], temp[14], temp[15]],
            ];
        }
    }

    return matrix;
}

const AppStateContextProvider = props => {

    const [boardState, setBoardState] = useState({
        boardMatrix: null,
        minWordSize: 3,
        maxWordSize: 10
    });
    const [appState, setAppState] = useState({
        boardWords: [],
        boardWordsInputs: {},

        currentWord: '',
        highlightPositions: [],

        badChars: [],
        wordCalcInProgress: false,
        setCurrentWord: function(w, pos) {
            setAppState(prev => ({
                ...prev,
                currentWord: w,
                highlightPositions: pos
            }));
        },
        setBoardFromString: function(boardStr) {
            var uriBoard = buildBoardFromURI(boardStr);
            if (uriBoard !== null) {
                setBoardState(prevState => ({
                    ...prevState,
                    boardMatrix: uriBoard,
                    // TODO: Make this true...
                    badChars: []
                }));
                appState.generateWords(boardState.minWordSize, boardState.maxWordSize, uriBoard);
            }

        },
        setMinWordSize: function(boardState, i) {
            if (i < MIN_WORD_SIZE) {
                return;
            }
            setBoardState(prevState => ({
                ...prevState,
                minWordSize: i
            }));
            appState.generateWords(i, boardState.maxWordSize, boardState.boardMatrix);
        },
        setMaxWordSize: function(boardState, i) {
            if (i > MAX_WORD_SIZE) {
                return;
            }
            setBoardState(prevState => ({
                    ...prevState,
                    maxWordSize: i
                })
            );
            appState.generateWords(boardState.minWordSize, i, boardState.boardMatrix);
        },
        randomBoard: function(boardState) {
            var newBoard = board.build();

            var boardString = board.boardToString(newBoard);
            window.history.pushState({}, 'Boggle Solver - ' + boardString, '/' + boardString);

            setBoardState(prevState => ({
                ...prevState,
                boardMatrix: newBoard,
                badChars: []
            }));
            appState.generateWords(boardState.minWordSize, boardState.maxWordSize, newBoard);
        },
        setLetter: function(boardState, x,y,newLetter) {
            var m = _.cloneDeep(boardState.boardMatrix);
            m[x][y] = newLetter;
            var badChars = isBoardValid(m);
            setBoardState(prevState => ({
                ...prevState,
                boardMatrix: m
            }));

            if (badChars.length) {
                setAppState(prevState => ({
                    ...prevState,
                    badChars: badChars
                }));
            } else {
                appState.generateWords(
                    boardState.minWordSize,
                    boardState.maxWordSize, 
                    m
                );
            }
        },
        generateWords: function(min = null, max = null, boardMatrix = null) {

            const worker = _generateWordsWorker;

            if (appState.wordCalcInProgress) {
                console.log("Skipping, word calc already happening");
                return;
            }

            setAppState(prevState => ({
                ...prevState,
                currentWord: '',
                highlightPositions: [],
                wordCalcInProgress: true,
                badChars: []
            }));

            worker.postMessage({
                min: min,
                max: max,
                matrix: boardMatrix
            });

            worker.onerror = function(err) {
                alert("Unexpected update error! " + err);
                setAppState(prevState => ({
                    ...prevState,
                    wordCalcInProgress: false
                }));
            }

            worker.onmessage = (e) => {
                const data = e.data;
                setAppState(prevState => ({
                    ...prevState,
                    boardWords: data.words,
                    boardWordsInputs: data.inputs,
                    wordCalcInProgress: false
                }));
            };
        }
    });


    const isBoardValid = function(inputMatrix) {
        // TODO: This is a constant really... we should figure this out once instead.
        var okChars = letters.validDisti();

        // Build equiv. with boardMatrix
        var chars = [];
        inputMatrix.forEach(function(x) {
            x.forEach(function(cc) {
                if (!chars[cc]) {
                    chars[cc] = 1;
                } else {
                    chars[cc]++;
                }
            });
        });

        var badChars = [];
        console.log(typeof chars);
        for (const [val, count] of Object.entries(chars)) {
            var okCount = okChars[val];
            if (count > okCount) {
                badChars.push(val);
            }
        }

        return badChars;
    }

    return <AppStateContext.Provider value={{ appState, setAppState, boardState, setBoardState }}>{props.children}</AppStateContext.Provider>;
};

export default AppStateContextProvider;
