import React from 'react';

import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import GameWrapper from './GameWrapper';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

function App() {

    return (
        <div className="App">
            <header className="App-header">
                <h2 className="boggle-title">
                    <i className="fas fa-puzzle-piece header-puzzle"></i>
                    &nbsp;
                    Boggle Solver
                </h2>

                <Router>
                <Switch>
                    <Route path="/:boardURI([a-zA-Z]{16,})">
                        <GameWrapper />
                    </Route>
                    <Route path="/">
                        <GameWrapper />
                    </Route>
                </Switch>
                </Router>
            </header>

            <div className="docs">
                <h2>What is Boggle?</h2>
                <p>Boggle is a word game. The game begins by shaking a covered
        tray of 16 cubic dice, each with a different letter printed on each of
        its sides. The dice settle into a 4×4 tray so that only the top letter
        of each cube is visible. After they have settled into the grid a timer is
        started. Players compete to find the most unique words of three or more letters.</p>
        <p> Read more about the rules and origins of the game on the <a href="https://en.wikipedia.org/wiki/Boggle">Boggle wikipedia page</a>.</p>

                <h2>What is the Boggle Solver website?</h2>
                <p>As a semi-regular player of Boggle I was curious to know which words
        I missed from a given set of letters. The idea of this site is that after the game you can quickly
        enter your board letters and see which high-scoring (or just interesting) words you've missed
        out on.</p>
            </div>

            <div className="docs docs--credits">
                <p><small>A <a href="https://falkus.co" target="_blank" rel="noreferrer">Falkus.co</a> Project</small></p>
            </div>
        </div>
    );
}

export default App;
