import React, { useState } from 'react';

import WebBoard from './BoggleUI/WebBoard';
import WordList from './BoggleUI/WordList';
import ControlBar from './BoggleUI/ControlBar';
import DebugBar from './BoggleUI/DebugBar';
import AppStateContextProvider from "./BoggleUI/contexts/AppStateContext";

import Tour from 'reactour'

function App() {
    const [showHelp, setShowHelp] = useState(false);

    const steps = [
        {
            selector: '.boggle-board-table',
            content: 'You can click and select each letter to match your board.',
        },
        {
            selector: '.first-step',
            content: <p>Or enter the letters from the top-left to bottom-right straight into the browser address bar, e.g.
            {window.location.origin}<strong>{window.location.pathname}</strong></p>
        },
        {
            selector: '.WordListControl',
            content: 'You can sort and filter to focus only on the high scoring words you might have missed!'
        },
        {
            selector: '.WordList',
            content: 'Click on a word to see the definition.'
        },
    ];

    const closeTour = function() {
        setShowHelp(false);
    }

    return (
        <>
            <AppStateContextProvider>
                <Tour steps={steps} isOpen={showHelp} onRequestClose={closeTour} />

                <div className="split-display">
                    <div className="board-half">
                        <ControlBar setShowHelp={setShowHelp} />
                        <WebBoard />
                    </div>
                    <div className="word-half">
                        <WordList />
                    </div>
                </div>

                <DebugBar />
            </AppStateContextProvider>
        </>
    );
}

export default App;
